import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import unitNumber from '@/utils/unitNumber';

const DICTIONARY = {
};
// 入库类型
const RECEIPT_TYPE = [{
  value: 'ORDER',
  label: '订单入库',
}, {
  value: 'RETURNED',
  label: '客退入库',
}, {
  value: 'REJECTED',
  label: '拒收入库',
}, {
  value: 'TRANSFER',
  label: '调拨入库',
}, {
  value: 'SETUP',
  label: '期初建账',
}, {
  value: 'VIRTUAL',
  label: '虚拟入库',
}, {
  value: 'THROUGH',
  label: '经由品入库',
}, {
  value: 'OTHER',
  label: '其它入库',
}];
const SOURCE = [{
  value: 'OMS',
  label: 'OMS',
}, {
  value: 'WMS',
  label: 'WMS',
}];
const RESOURCE_TYPE = [{
  value: 'PDA',
  label: 'PDA',
}, {
  value: 'PC',
  label: '纸单',
}];
// 温层
const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
const CROSSDOCKING = [{
  value: true,
  label: '是',
}, {
  value: false,
  label: '否',
}];
export {
  DICTIONARY,
  RECEIPT_TYPE,
  SOURCE,
  TEMPERATURE_LAYER,
};

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

export const TABLECOLUMN = [{
  type: 'selection',
  width: 55,
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '验收时间',
  prop: 'operationTime',
  ...DateTime,
}, {
  label: '入库单号',
  prop: 'receiptOrderNo',
  minWidth: 180,
}, {
  label: '货主订单号',
  prop: 'customerOrderNo',
  minWidth: 180,
}, {
  label: '货主名称',
  prop: 'shipperName',
  minWidth: 120,
}, {
  label: '入库单类型',
  prop: 'receiptType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.receiptType, RECEIPT_TYPE),
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 120,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  minWidth: 120,
}, {
  label: '预约数量',
  prop: 'bookingNum',
  minWidth: 160,
  formatter: (row) => (row.bookingNum
    ? unitNumber.calculate({ ...row, smallUnitNumber: row.bookingNum }).txt : row.bookingNum),
}, {
  label: '预约数量(默认单位)',
  prop: 'defaultUnitBookingNum',
  minWidth: 160,
}, {
  label: '验收数量',
  prop: 'acceptanceNum',
  minWidth: 160,
  formatter: (row) => (row.acceptanceNum
    ? unitNumber.calculate({ ...row, smallUnitNumber: row.acceptanceNum }).txt : row.acceptanceNum),
}, {
  label: '验收数量(默认单位)',
  prop: 'defaultUnitAcceptanceNum',
  minWidth: 160,
}, {
  label: '批次',
  prop: 'batchNo',
  minWidth: 190,
}, {
  label: 'LPN',
  prop: 'lpn',
  minWidth: 180,
}, {
  label: '生产日期',
  prop: 'productDate',
  ...DateTime,
}, {
  label: '到期日期',
  prop: 'expireDate',
  ...DateTime,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  minWidth: 160,
  formatter: (row) => TEMPERATURE_LAYER[row.temperatureLayer],

},
{
  label: '容器号',
  prop: 'containerCode',
  minWidth: 160,
},
{
  label: '验收重量(KG)',
  prop: 'acceptanceWeight',
  minWidth: 160,
},
{
  label: '验收体积(m³)',
  prop: 'acceptanceVolume',
  minWidth: 160,
},
{
  label: '辅助数量',
  prop: 'auxiliaryUnitNum',
  minWidth: 100,
},
{
  label: '是否越库',
  prop: 'crossDocking',
  minWidth: 100,
  formatter: (row) => filterLabel(row.crossDocking, CROSSDOCKING),
},
{
  label: '货品备注',
  prop: 'receiptOrderDetailRemark',
  minWidth: 100,
},
{
  label: '验收方式',
  prop: 'resourceType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.resourceType, RESOURCE_TYPE),
},
{
  label: '验收人',
  prop: 'operatorStaffName',
  minWidth: 100,
}];
export const serchFields = [
  {
    label: '入库单号',
    prop: 'receiptOrderNoLike',
    component: FormType.INPUT,
  }, {
    label: '入库单类型',
    prop: 'receiptType',
    component: FormType.SELECT,
    options: RECEIPT_TYPE,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '货主订单号',
    prop: 'customerOrderNoLike',
    component: FormType.INPUT,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsNoLike',
    component: FormType.INPUT,
  }, {
    label: '货品名称',
    prop: 'goodsNameLike',
    component: FormType.INPUT,
  }, {
    label: '批次号',
    prop: 'batchNoLike',
    component: FormType.INPUT,
  }, {
    label: '容器号',
    prop: 'containerNoLike',
    component: FormType.INPUT,
  }, {
    label: '是否越库',
    prop: 'allowCross',
    component: FormType.SELECT,
    options: CROSSDOCKING,
  }, {
    label: '验收方式',
    prop: 'resourceType',
    component: FormType.SELECT,
    options: RESOURCE_TYPE,
  }, {
    label: '验收人',
    prop: 'operatorStaffNameLike',
    component: FormType.INPUT,
  }, {
    label: '验收时间',
    prop: 'acceptanceTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },
  },
];
