import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:验收明细分页
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14400
 */
export function acceptancePage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/acceptance_page', data, {
    params,
  });
}

/**
 * @description:验收明细导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14402
 */
export function acceptanceExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/receipt_order/acceptance_export', data, { fileName: '验收明细', extension: 'xlsx' });
}
